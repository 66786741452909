<template>
  <div>
    <MyHeader />
    <main id="content">
      <ProfileMenu />
      <div class="container mt-4 mt-lg-5">
        <div class="row">
          <div class="col-auto">
            <h1 class="title">
              Messages
            </h1>
          </div>
          <!-- <div class="col-auto">
            <select class="form-control form-control-sm mt-lg-2">
              <option>Date</option>
              <option>Status</option>
            </select>
          </div> -->
          <!-- <div class="col-auto ml-auto mt-lg-1">
            <button class="btn btn-outline-primary">
              <i class="icon-equalizer" /> FILTER
            </button>
          </div> -->
        </div>
        <div class="row"
             v-if="getMessages && getMessages.length > 0">
          <div class="col-12">
            <ul class="message-list">
              <li v-for="(message, index) in getMessages"
                  :key="index">
                <div class="user">
                  <router-link
                    :to="{ name: 'user-message', params: { id: message.booking.id }}">
                    <div class="thumb">
                      <img :src="message.booking.concierge && message.booking.concierge.avatar ? message.booking.concierge.avatar.path : '/img/user-thumb.jpg'">
                    </div>
                    <div class="name"
                         v-if="message.booking.concierge && message.booking.concierge.name && message.booking.concierge.surname">
                      <h4>{{ message.booking.concierge.name }} {{ message.booking.concierge.surname }}</h4>
                      <p>Your Concierge</p>
                    </div>
                  </router-link>
                </div>
                <router-link class="msg"
                             :to="{ name: 'user-message', params: { id: message.booking.id }}">
                  <span class="badge badge-danger"
                        v-if="message.status === 0 && message.user_id !== $store.getters.getUser.id">NEW</span> {{ message.message }}
                </router-link>
                <div class="status confirmed"
                     v-if="message && message.booking.status">
                  <label>Status:</label>
                  <span v-if="message.booking.status === 1">Confirmed</span>
                  <span v-if="message.booking.status === 2">Booking Request</span>
                  <span v-if="message.booking.status === 3">Cancelled</span>
                  <span v-if="message.booking.status === 4">Past Trips</span>
                  <span v-if="message.booking.status === 5">Pending User Payment</span>
                </div>
                <div class="info"
                     v-if="message && message.booking.property && message.booking.property.name && message.booking.property.country">
                  <label>Trip info:</label>
                  <p>{{ message.booking.property.name }} | {{ message.booking.property.state.name }}, {{ message.booking.property.country.name }}  <small>{{ message.booking.check_in|beatyDate }} - {{ message.booking.check_out|beatyDate }}   | {{ message.booking.travelers }}  travelers</small></p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <h3 v-if="getMessages.length <= 0">
          No messages were found.
        </h3>
      </div>
      <ContactOurExperts />
    </main>
    <MyFooter />
  </div>
</template>

<script lang="ts">
import ProfileMenu from '../../components/users/ProfileMenu.vue';
import ContactOurExperts from '../../components/users/ContactOurExperts.vue';

export default {
  name: 'UserMessages',
  components: {
    ProfileMenu,
    ContactOurExperts,
  },
  data() {
    return {
      messages: {},
    };
  },
  computed: {
    getMessages() {
      return this.messages;
    }
  },
  created() {
    this.$axios.get('/v1/account/messages').then((response) => {
      this.messages = response.data.data.messages;
    });
  },
};
</script>

<style lang="scss" scoped>

</style>
